import { chakra, ChakraProps, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { isMessageKey, MessageKey, useTranslatedString } from "src/i18n/i18n";

type FormErrorMessageProps = {
	error?: { message?: string };
	message?: string;
} & ChakraProps;

const fallbackErrorMessage = (str?: string): MessageKey => {
	if (str && isMessageKey(str)) {
		return str;
	}

	return "zod.missing";
};

export const FormErrorMessageI18n: React.FC<FormErrorMessageProps> = ({
	error,
	message,
	...props
}) => {
	const t = useTranslatedString();
	const isVisible = Boolean(error?.message ?? message);
	const messageKey = fallbackErrorMessage(error?.message ?? message);
	const styles = useMultiStyleConfig("FormErrorMessageI18n", { isVisible });

	return (
		<chakra.div __css={styles.wrapper} {...props}>
			{isVisible ? t(messageKey) : <>&nbsp;</>}
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
