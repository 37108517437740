import {
	GetAllInsuranceProductsForPremiumCalcQuery,
	GetAllInsuranceProductsQuery,
} from "src/__generated__/cms-schema.codegen";
import { isHiddenProduct } from "src/lib/productAndTGFilters";
import { PortalType } from "src/lib/ssr/utils";
import { truthy } from "src/lib/utils";
import { useLoginInfo } from "src/queries/emil/account";
import { Role } from "../AuthGuard/AuthGuard";
import { getFormattedProducts } from "../ProductOverview/utils";

export type PremiumCalcProducts =
	| GetAllInsuranceProductsQuery["allInsuranceProducts"]
	| GetAllInsuranceProductsForPremiumCalcQuery["allBrands"][number]["_allReferencingInsuranceProducts"]
	| undefined;

export const filterInsuranceProductsForForm = (
	allInsuranceProducts: PremiumCalcProducts,
	loginInfo: ReturnType<typeof useLoginInfo>,
	rolePermission: Role | null,
	portal: PortalType,
) => {
	if (!allInsuranceProducts) {
		return [];
	}

	const products = allInsuranceProducts
		.filter(
			(product) =>
				!isHiddenProduct(
					product.slug,
					loginInfo,
					rolePermission,
					portal,
				),
		)
		.filter(truthy);

	const { cheapestProducts, singleProducts } = getFormattedProducts(products);

	return [...singleProducts, ...cheapestProducts];
};

// 🔬 TBD: Please evaluate
