import groupBy from "lodash/groupBy";
import uniq from "lodash/uniq";
import { useRouter } from "next/router";
import { useGetInsuranceProductsBySlugQuery } from "src/__generated__/client.codegen";
import { resolveTenantAware } from "src/lib/emilTenantMaps";
import { useSiteSettings } from "src/lib/hooks";
import { isString, truthy } from "src/lib/utils";
import { useGetProductsFromStructuredText } from "src/sections/ProductComparison/utils";
import { ProductOverviewProps } from "./ProductOverview";
import { PremiumCalcProducts } from "../PremiumCalcModal/utils";

export type Products = NonNullable<ProductOverviewProps["products"]> &
	PremiumCalcProducts;

// construct a given group prop (key) from products in the group
const getGroupInfo = (group: Products, key: keyof Products[number]) => {
	const values = group.map((product) => product[key]).filter(truthy);
	const separator = values.length === 1 ? "" : " ";

	if (!values.length) {
		return null;
	}

	return { values, separator };
};

/** This function serves the purpose of making grouping of products as easy as possible.
 * 	It outputs three results: single products (i.e. products that have a unique slug), productGroups
 *  (an array with arrays of products with the same slug) and cheapestProducts (an array with an array of
 *  the cheapest product of the group).
 *
 *  The product data is enriched with hasGroup to know if the product is part of a group (used for
 * 	function createProductIdAndHref), and an array of the target groups that belong to the product (used for component
 * 	FamilySubMenu).
 */
export const getFormattedProducts = (products: Products) => {
	// gather products with same slug into arrays
	// [[{ slug: "berufshaftpflichtversicherung", ... }, { slug: "berufshaftpflichtversicherung", ... }], [{ slug: "some-other-slug", ... }]]
	const groups = Object.values(groupBy(products, "slug"));

	const singleProducts = groups
		.filter((group) => group.length === 1)
		.flat()
		.map((product) => ({
			...product,
			productCode: resolveTenantAware(product.productCode),
			productName: resolveTenantAware(product.productName),
			hasGroup: false,
			targetGroups: [product.targetGroup].filter(truthy),
		}));

	const productGroups = groups
		.filter((group) => group.length > 1)
		.map((group) =>
			group.map((product) => ({
				...product,
				productCode: resolveTenantAware(product.productCode),
				productName: resolveTenantAware(product.productName),
				hasGroup: true,
				targetGroups: [
					...group
						.map(({ targetGroup }) => targetGroup)
						.filter(truthy),
				],
				image: group.find(({ image }) => image !== null)?.image ?? null,
			})),
		);

	// for each group, take the cheapest one as the representing product for this group
	const cheapestProducts = productGroups.map((group) => {
		// create the group name & description using the group names & descriptions from all products within the group
		const groupNames = getGroupInfo(group, "productGroupName");
		const groupName = groupNames?.values.join(groupNames.separator);

		const groupDescriptions = getGroupInfo(
			group,
			"productGroupDescription",
		);
		const groupDescription = groupDescriptions?.values.join(
			groupDescriptions.separator,
		);

		return group.reduce((min, curr) => {
			// if for some reason we don't have prices to compare, return the array's first product
			if (!curr.currentVersion.price || !min.currentVersion.price) {
				return min;
			}

			// choose the cheaper product and ...
			const product =
				curr.currentVersion.price < min.currentVersion.price
					? curr
					: min;

			// take the name & description as fallbacks for group name & description
			const {
				name,
				currentVersion: { productDescription },
			} = product;

			// ...return it together with the group props (if they are defined)
			return {
				...product,
				name: groupName ?? name,
				currentVersion: {
					...product.currentVersion,
					productDescription: groupDescription ?? productDescription,
				},
			};
		});
	});

	return { productGroups, singleProducts, cheapestProducts };
};

const useGetProductsFromCMS = (
	productSlugs: Array<string>,
	brandSlug?: string | Array<string>,
) => {
	const { locale } = useSiteSettings();

	const { data, isLoading } = useGetInsuranceProductsBySlugQuery({
		locale,
		slugs: productSlugs,
	});

	if (!isString(brandSlug)) {
		return { isLoading, products: [] };
	}

	if (!data?.allInsuranceProducts || data.allInsuranceProducts.length === 0) {
		return { isLoading, products: [] };
	}

	return {
		isLoading,
		products: data.allInsuranceProducts,
	};
};

/** Takes a bunch of products, extracts their slugs and fetches all fitting products from the CMS.
 * We need this as sometimes, we want to group products by slug but only have the unique slugs initially.
 */
export const useGetProducts = (
	productsFromStrucText: ReturnType<
		typeof useGetProductsFromStructuredText
	>["products"],
) => {
	const linkedProducts = productsFromStrucText
		?.filter(truthy)
		.map(({ linkedProduct }) => linkedProduct)
		.filter(truthy);

	const productSlugs = uniq(linkedProducts?.map(({ slug }) => slug));

	const router = useRouter();
	const { brandSlug } = router.query;

	const { isLoading, products } = useGetProductsFromCMS(
		productSlugs,
		brandSlug,
	);

	return {
		isLoading,
		products: products.length ? products : undefined,
	};
};

// 🔬 TBD
